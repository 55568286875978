import { forwardRef } from 'react';
import classNames from 'classnames';
import { KardLocation } from 'query/kardTypes';
import Logo from 'routes/sections/rewards/Logo';
import { TextStyle } from 'assets/styles/styles';
import BulletIcon from 'assets/icons/BulletIcon';
import PinIcon from 'assets/icons/PinIcon';
import styles from './Location.module.css';
import { formatDistance, milesAwayFrom } from '../../distance';

const Location = forwardRef<
  HTMLButtonElement,
  {
    location: KardLocation;
    offer?: KardLocation['offers'][0];
    onClick?: JSX.IntrinsicElements['button']['onClick'];
  }
>(({ location, offer, onClick }, ref) => (
  <button onClick={onClick} className={styles.location} ref={ref} type="button">
    <div className={styles.logoStoreDetails}>
      <Logo size={46} className={styles.logo} merchant={location.merchant} />
      <div className={styles.storeDetails}>
        <span className={TextStyle.CaptionMedium}>{location.name}</span>
        <span className={classNames(TextStyle.Tiny, styles.details)}>
          <span>{location.address.street}</span>
          <BulletIcon />
          <span style={{ color: 'var(--Green)' }}>Open</span>
        </span>
        <span className={TextStyle.TinyBold}>
          {offer && `${offer.totalCommission}% cash back`}
        </span>
      </div>
    </div>
    <div className={styles.distance}>
      <PinIcon />
      <span className={TextStyle.Tiny}>
        {formatDistance(milesAwayFrom(location.geoLocation))}mi
      </span>
    </div>
  </button>
));

export default Location;
