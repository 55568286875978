import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const NavHomeIcon: FC<SolidIconProps> = ({
  fill = 'var(--300, #03276D)',
  className,
}) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.1134 2.47698C11.7524 2.19639 11.2471 2.19639 10.8861 2.47698L2.83017 8.73845C2.58643 8.9279 2.44385 9.2193 2.44385 9.528V21C2.44385 21.5523 2.89156 22 3.44385 22H7.67433C8.22662 22 8.67433 21.5523 8.67433 21V15.1757C8.67433 14.6234 9.12205 14.1757 9.67433 14.1757H13.3252C13.8775 14.1757 14.3252 14.6234 14.3252 15.1757V21C14.3252 21.5523 14.7729 22 15.3252 22H19.5557C20.108 22 20.5557 21.5523 20.5557 21V9.52801C20.5557 9.2193 20.4131 8.9279 20.1694 8.73845L12.1134 2.47698Z"
      fill={fill}
    />
  </svg>
);

export default NavHomeIcon;
