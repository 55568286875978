import { type FC } from 'react';
import SolidIconProps from './SolidIconProps';

const PinIcon: FC<SolidIconProps> = ({
  fill = 'var(--500, "#8D8D94")',
  className,
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="pin">
      <g id="Vector">
        <path
          d="M7.99983 8.4327C8.7853 8.4327 9.42205 7.81856 9.42205 7.06098C9.42205 6.30341 8.7853 5.68927 7.99983 5.68927C7.21436 5.68927 6.57761 6.30341 6.57761 7.06098C6.57761 7.81856 7.21436 8.4327 7.99983 8.4327Z"
          fill={fill}
        />
        <path
          d="M7.99983 1.83337C6.58581 1.83483 5.23013 2.37724 4.23027 3.3416C3.2304 4.30596 2.66802 5.6135 2.66651 6.97731C2.66436 8.07756 3.03186 9.14886 3.71398 10.0307C3.72687 10.0524 3.74112 10.0732 3.75664 10.0932L7.43094 14.8887C7.49707 14.9749 7.58324 15.045 7.68253 15.0933C7.78183 15.1416 7.89148 15.1667 8.00268 15.1667C8.11388 15.1667 8.22353 15.1416 8.32282 15.0933C8.42212 15.045 8.50829 14.9749 8.57441 14.8887L12.2459 10.0932C12.2614 10.0735 12.2756 10.0528 12.2885 10.0314C12.9696 9.14895 13.3361 8.07746 13.3332 6.97731C13.3316 5.6135 12.7693 4.30596 11.7694 3.3416C10.7695 2.37724 9.41385 1.83483 7.99983 1.83337ZM7.99983 9.80442C7.43726 9.80442 6.88731 9.64352 6.41955 9.34206C5.95178 9.04061 5.5872 8.61215 5.37192 8.11085C5.15663 7.60955 5.1003 7.05794 5.21005 6.52577C5.3198 5.99359 5.59071 5.50476 5.98851 5.12108C6.38631 4.73741 6.89314 4.47612 7.44491 4.37027C7.99668 4.26441 8.5686 4.31874 9.08835 4.52638C9.6081 4.73403 10.0523 5.08566 10.3649 5.53681C10.6774 5.98797 10.8443 6.51838 10.8443 7.06098C10.8443 7.78859 10.5446 8.48639 10.0112 9.00088C9.47772 9.51538 8.75422 9.80442 7.99983 9.80442Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default PinIcon;
