import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const BellIcon: FC<SolidIconProps> = ({
  fill = 'var(--0, white)',
  className,
}) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 0C8.14356 0 6.36309 0.737498 5.05033 2.05025C3.73758 3.36301 3.00008 5.14348 3.00008 7C3.00008 10.3527 2.28301 12.4346 1.62218 13.6461C1.29039 14.2544 0.967304 14.6535 0.742875 14.8904C0.6304 15.0091 0.542016 15.0878 0.4888 15.1322C0.462178 15.1544 0.444325 15.168 0.436625 15.1737L0.433767 15.1758C0.0752824 15.4221 -0.0833568 15.8725 0.0429858 16.2898C0.170684 16.7115 0.559411 17 1.00008 17H19.0001C19.4407 17 19.8295 16.7115 19.9572 16.2898C20.0835 15.8725 19.9249 15.4221 19.5664 15.1758L19.5635 15.1737C19.5558 15.168 19.538 15.1544 19.5114 15.1322C19.4581 15.0878 19.3698 15.0091 19.2573 14.8904C19.0329 14.6535 18.7098 14.2544 18.378 13.6461C17.7171 12.4346 17.0001 10.3527 17.0001 7C17.0001 5.14349 16.2626 3.36301 14.9498 2.05025C13.6371 0.737498 11.8566 0 10.0001 0ZM16.6222 14.6039C16.6983 14.7434 16.7747 14.8753 16.8508 15H3.1494C3.22549 14.8753 3.30188 14.7434 3.37797 14.6039C4.21715 13.0654 5.00008 10.6473 5.00008 7C5.00008 5.67392 5.52686 4.40215 6.46454 3.46447C7.40223 2.52678 8.674 2 10.0001 2C11.3262 2 12.5979 2.52678 13.5356 3.46447C14.4733 4.40215 15.0001 5.67392 15.0001 7C15.0001 10.6473 15.783 13.0654 16.6222 14.6039Z"
      fill={fill}
    />
    <path
      d="M9.1351 19.4982C8.85798 19.0205 8.24605 18.8579 7.76833 19.135C7.2906 19.4121 7.12798 20.024 7.4051 20.5018C7.66881 20.9564 8.04733 21.3337 8.50276 21.5961C8.95818 21.8584 9.47453 21.9965 10.0001 21.9965C10.5257 21.9965 11.042 21.8584 11.4974 21.5961C11.9529 21.3337 12.3314 20.9564 12.5951 20.5018C12.8722 20.024 12.7096 19.4121 12.2319 19.135C11.7541 18.8579 11.1422 19.0205 10.8651 19.4982C10.7772 19.6498 10.651 19.7756 10.4992 19.863C10.3474 19.9504 10.1753 19.9965 10.0001 19.9965C9.82491 19.9965 9.65279 19.9504 9.50098 19.863C9.34918 19.7756 9.223 19.6498 9.1351 19.4982Z"
      fill={fill}
    />
  </svg>
);

export default BellIcon;
