import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { offersQuery, userQuery } from 'query/queries';
import { TextStyle } from 'assets/styles/styles';
import BellIcon from 'assets/icons/BellIcon';
import ListIcon from 'assets/icons/ListIcon';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import styles from './Home.module.css';
import Logo from '../rewards/Logo';

const Home: FC = () => {
  const { data: user } = useQuery(userQuery);
  const { data: offers } = useQuery(offersQuery);

  return (
    <main className={styles.main}>
      <section className={styles.topSection}>
        <div className={styles.bar}>
          <h2 className={TextStyle.P1Medium}>
            Welcome back{user?.firstName && `, ${user.firstName}`}
          </h2>
          <BellIcon />
        </div>
        <div className={styles.accounts}>
          <div className={styles.account}>
            <div className={styles.bar}>
              <span className={TextStyle.TinyMedium}>Checking</span>
              <span className={TextStyle.TinyMedium}>•••••••9472</span>
            </div>
            <span className={TextStyle.H1Bold}>$1,287.00</span>
          </div>
          <div className={styles.account}>
            <div className={styles.bar}>
              <span className={TextStyle.TinyMedium}>Credit</span>
              <span className={TextStyle.TinyMedium}>•••••••5729</span>
            </div>
            <span className={TextStyle.H1Bold}>$492.87</span>
          </div>
        </div>
        <div className={styles.moneyActions}>
          <div className={styles.action}>
            <div className={styles.actionIconWrapper}>
              <ListIcon fill="var(--700)" className={styles.actionIcon} />
            </div>
            <span className={TextStyle.TinyMedium}>Transfer</span>
          </div>

          <div className={styles.action}>
            <div className={styles.actionIconWrapper}>
              <ListIcon fill="var(--700)" className={styles.actionIcon} />
            </div>
            <span className={TextStyle.TinyMedium}>Send</span>
          </div>

          <div className={styles.action}>
            <div className={styles.actionIconWrapper}>
              <ListIcon fill="var(--700)" className={styles.actionIcon} />
            </div>
            <span className={TextStyle.TinyMedium}>Request</span>
          </div>

          <div className={styles.action}>
            <div className={styles.actionIconWrapper}>
              <ListIcon fill="var(--700)" className={styles.actionIcon} />
            </div>
            <span className={TextStyle.TinyMedium}>Pay Bills</span>
          </div>
        </div>
      </section>
      <section className={styles.bottomSection}>
        <div className={styles.bar}>
          <h2 className={TextStyle.P1Bold}>Featured Rewards</h2>
          <Link
            to="/rewards"
            className={classNames(TextStyle.CaptionBold, styles.linkWithIcon)}
          >
            View all <ArrowRightIcon fill="currentcolor" />
          </Link>
        </div>
        <div className={classNames(styles.bar, styles.logos)}>
          {offers?.map((offer) => (
            <Logo size={50} merchant={offer.merchant} key={offer._id} />
          ))}
        </div>
        <div className={styles.bar}>
          <h2 className={TextStyle.P1Bold}>Recent Transactions</h2>
          <Link
            to="/transactions"
            className={classNames(TextStyle.CaptionBold, styles.linkWithIcon)}
          >
            View all <ArrowRightIcon fill="currentcolor" />
          </Link>
        </div>
        <div className={styles.transaction}>
          <div className={styles.bar}>
            <span className={TextStyle.CaptionMedium}>CVS</span>
            <span className={TextStyle.CaptionMedium}>$50</span>
          </div>
          <span className={classNames(TextStyle.Tiny, styles.transactionDate)}>
            Today
          </span>
        </div>
        <div className={styles.transaction}>
          <div className={styles.bar}>
            <span className={TextStyle.CaptionMedium}>Dell</span>
            <span className={TextStyle.CaptionMedium}>$217.60</span>
          </div>
          <span className={classNames(TextStyle.Tiny, styles.transactionDate)}>
            Today
          </span>
        </div>
        <div className={styles.transaction}>
          <div className={styles.bar}>
            <span className={TextStyle.CaptionMedium}>Allbirds</span>
            <span className={TextStyle.CaptionMedium}>$125.46</span>
          </div>
          <span className={classNames(TextStyle.Tiny, styles.transactionDate)}>
            Today
          </span>
        </div>
      </section>
    </main>
  );
};

export default Home;
