import haversine from 'haversine';
import currentLocation from '../currentLocation';

export const milesAwayFrom = (
  geoLocation: {
    longitude: number;
    latitude: number;
  },
  curLocation = currentLocation,
) =>
  haversine(geoLocation, curLocation, {
    unit: 'mile',
  });

/**
 * Format a distance intelligently:
 * - if the distance is less than 100 miles:
 *   - round to 1 decimal place
 *   - if the decimal value is 0, strip it
 * - else:
 *   - round to 0 decimal places
 * @param distance the distance to format
 * @returns
 */
export const formatDistance = (distance: number) =>
  parseFloat(distance.toFixed(distance < 1e2 ? 1 : 0)).toString();
