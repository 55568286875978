import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { TextStyle } from 'assets/styles/styles';
import styles from './Button.module.css';

export type ButtonVariant = 'disabled' | 'primary' | 'secondary';

const Button: FC<
  PropsWithChildren<
    {
      variant?: ButtonVariant;
      className?: string;
    } & Pick<JSX.IntrinsicElements['button'], 'type' | 'disabled' | 'onClick'>
  >
> = ({
  children,
  variant = 'primary',
  className,
  type = 'button',
  disabled,
  onClick,
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={classNames(
      className,
      styles.button,
      styles[variant],
      TextStyle.CaptionBold,
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
