import { type FC } from 'react';
import classNames from 'classnames';
import { KardLocation } from 'query/kardTypes';
import MapPinIcon from 'assets/icons/MapPinIcon';
import { UtilityStyle } from 'assets/styles/styles';
import styles from './Pin.module.css';
import { FocusedLocationProps } from '../FocusedLocationProps';

const Pin: FC<
  {
    location: KardLocation;
  } & Partial<FocusedLocationProps>
> = ({ location, focusedLocationId, setFocusedLocationId }) => (
  <label className={classNames(UtilityStyle.CenterEverything, styles.pin)}>
    <input
      checked={focusedLocationId === location._id}
      onChange={() => setFocusedLocationId?.(location._id)}
      name="pin"
      type="radio"
      value={location.name}
      disabled={setFocusedLocationId === undefined}
    />
    <MapPinIcon
      bodyClassName={
        setFocusedLocationId !== undefined ? styles.clickable : undefined
      }
      merchant={location.merchant}
      fill={focusedLocationId === location._id ? 'var(--900)' : 'var(--0)'}
    />
  </label>
);

export default Pin;
