import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styles from './AppNotification.module.css';

const GentleSlideTopCenter = cssTransition({
  enter: styles.enter,
  exit: styles.exit,
  collapse: false,
});

export default () => (
  <ToastContainer
    className={styles.toastContainer}
    position="top-center"
    autoClose={false}
    closeButton={false}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    draggablePercent={100}
    draggableDirection="y"
    pauseOnHover
    transition={GentleSlideTopCenter}
    toastClassName={styles.toast}
    bodyClassName={styles.toastBody}
  />
);
