import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const NavRewardsIcon: FC<SolidIconProps> = ({
  fill = 'var(--300, #03276D)',
  className,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23 6C23 5.175 22.325 4.5 21.5 4.5H3.5C2.675 4.5 2 5.175 2 6V8.25H23V6Z"
      fill={fill}
    />
    <path
      d="M2 18C2 18.825 2.675 19.5 3.5 19.5H21.5C22.325 19.5 23 18.825 23 18V10.5H2V18ZM8.65625 15.75H16.3438C16.7047 15.75 17 16.0453 17 16.4062C17 16.7672 16.7047 17.0625 16.3438 17.0625H8.65625C8.29531 17.0625 8 16.7672 8 16.4062C8 16.0453 8.29531 15.75 8.65625 15.75ZM5.28125 15.75H5.84375C6.20469 15.75 6.5 16.0453 6.5 16.4062C6.5 16.7672 6.20469 17.0625 5.84375 17.0625H5.28125C4.92031 17.0625 4.625 16.7672 4.625 16.4062C4.625 16.0453 4.92031 15.75 5.28125 15.75Z"
      fill={fill}
    />
  </svg>
);

export default NavRewardsIcon;
