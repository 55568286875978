import backend from './backend';

/**
 * Structure of an error response from the API.
 */
interface ErrorResponseBody {
  error: {
    status: number;
    message: string;
  };
}

/**
 * Make a JSON request to the backend API.
 * @param route endpoint to call.
 * @param body body to send as JSON.
 * @param init other options passed to a wrapped fetch call.
 * @returns a promise containing the API response body.
 */
export default async (
  route: string,
  body?: object,
  { headers: givenHeaders, ...init }: RequestInit = {},
) => {
  const res = await fetch(`${backend}${route}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...givenHeaders,
    },
    ...(body !== undefined ? { body: JSON.stringify(body) } : {}),
    ...init,
  }).catch((err: Error) => {
    throw new Error(`Error connecting to API: ${err.message}`);
  });

  const resBody = (await res.json().catch((err: Error) => {
    throw new Error(`Error while deserializing response: ${err.message}`);
  })) as Promise<object>;

  if (
    typeof resBody === 'object' &&
    'error' in resBody &&
    resBody.error !== undefined
  ) {
    throw new Error(
      `Server error: ${(resBody as ErrorResponseBody).error.message}`,
    );
  }

  if (!res.ok) {
    throw new Error(`Server error: code ${res.status}`);
  }

  return resBody;
};
