import { type FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { KardMerchant, KardReward } from 'query/kardTypes';
import { TextStyle } from 'assets/styles/styles';
import MerchantBurst from 'assets/images/MerchantBurst';
import styles from './RewardPage.module.css';

export interface RewardStateProps {
  reward: KardReward;
  merchant: KardMerchant;
}

const RewardPage: FC = () => {
  const { state } = useLocation() as { state: RewardStateProps | null };

  if (state === null) {
    return <Navigate to="/rewards" />;
  }

  const { reward, merchant } = state;

  return (
    <div className={styles.reward}>
      <MerchantBurst merchant={merchant} />
      <div className={classNames(TextStyle.P1, styles.label)}>
        <h1 className={TextStyle.H1Bold}>You got cash back!</h1>
        <p>
          Hooray! You&rsquo;ve earned cash back for shopping at{' '}
          {reward.reward?.name} on{' '}
          <span className={styles.date}>
            {new Date(
              reward.transaction.transactionTimeStamp,
            ).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </span>
          .
        </p>
        <p>Keep reaping the rewards! 🎉</p>
      </div>
    </div>
  );
};

export default RewardPage;
