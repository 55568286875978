import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TextStyle } from 'assets/styles/styles';
import { offersQuery } from 'query/queries';
import OffersList from 'routes/sections/rewards/OfferList';
import styles from './AvailableOffers.module.css';

const AvailableOffers: FC = () => {
  const { data: offers, isLoading, error } = useQuery(offersQuery);

  return (
    <div className={styles.availableOffers}>
      <h2 className={TextStyle.P1Bold}>Available Offers</h2>
      {isLoading && <>Loading&hellip;</>}
      {error && <>Could not load offers</>}
      {offers !== undefined && <OffersList offers={offers} />}
    </div>
  );
};

export default AvailableOffers;
