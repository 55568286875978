import { type FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { locationMapQuery, offersQuery, userQuery } from 'query/queries';
import { EffectStyle, TextStyle } from 'assets/styles/styles';
import Button from 'components/Button';
import { useNullablePubnub } from 'query/PubnubContext';
import styles from './OfferPage.module.css';
import simulateTransaction from './simulateTransaction';
import Logo from '../Logo';
import MapContainer from '../Locations/MapContainer';
import OfferDetails from '../OfferList/Offer/OfferDetails';

const OfferPage: FC = () => {
  const pubnub = useNullablePubnub();

  const { offerId } = useParams();
  const locationId = useSearchParams()[0].get('locationId');

  const { data: offers } = useQuery(offersQuery);

  const offer = offers?.find((someOffer) => someOffer._id === offerId);

  const conditionalLocationMapQuery = {
    ...locationMapQuery,
    enabled: locationId !== null,
  };
  const { data: locationMap } = useQuery(conditionalLocationMapQuery);

  const location =
    locationId !== null ? locationMap.get(locationId) ?? null : null;

  const conditionalUserQuery = {
    ...userQuery,
    enabled: offer?.offerType === 'ONLINE',
  };
  const { data: user } = useQuery(conditionalUserQuery);

  return (
    <div className={styles.offer}>
      {offer !== undefined && (
        <img
          className={styles.banner}
          src={offer.merchant.bannerImgUrl}
          alt={`Large banner for ${offer.merchant.name}`}
        />
      )}
      <Logo
        size={50}
        merchant={offer?.merchant}
        className={classNames(EffectStyle.Shadowed, styles.logo)}
      />
      <div className={classNames(styles.details, TextStyle.P1)}>
        <div className={styles.summary}>
          <h1 className={TextStyle.H1}>
            {offer?.merchant.name ?? <>Loading&hellip;</>}
          </h1>
          {offer !== undefined && (
            <OfferDetails full offer={offer} location={location ?? undefined} />
          )}
          {location !== null && (
            <span className={classNames(TextStyle.Tiny, styles.light)}>
              {location.address.street} {location.address.city},{' '}
              {location.address.state}
            </span>
          )}
          {locationId !== null && location !== null && (
            <div className={styles.map}>
              <MapContainer
                locationMap={new Map([[locationId, location]])}
                focusedLocationId={locationId}
              />
            </div>
          )}
        </div>
        {offer !== undefined && (
          <>
            <div className={styles.cashback}>
              <h2 className={TextStyle.P1Bold}>Offer</h2>
              <div className={styles.deal}>
                <b className={TextStyle.P1Bold}>{offer.totalCommission}%</b>{' '}
                cash back
                {offer.maxRewardAmount &&
                  ` up to $${parseFloat(
                    (offer.maxRewardAmount / 100).toString(),
                  )}`}
              </div>
            </div>
            <div className={styles.tnc}>
              <h2 className={TextStyle.P1Bold}>Terms and Qualifications</h2>
              <p className={styles.light}>{offer.terms}</p>
            </div>
            {location !== null && (
              <div className={styles.hours}>
                <h2 className={TextStyle.P1Bold}>Hours of Operation</h2>
                {Object.entries(location.operationHours).map(([day, hours]) => (
                  <div className={styles.dayHours}>
                    <span className={styles.day}>{day.toLowerCase()}</span>
                    <span>{hours}</span>
                  </div>
                ))}
              </div>
            )}
            {pubnub === null && (
              <Button variant="primary" type="button" disabled>
                Connecting to notification service&hellip;
              </Button>
            )}
            {offer.offerType === 'ONLINE' &&
              user !== undefined &&
              pubnub !== null && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => simulateTransaction(pubnub, user, offer)}
                >
                  Shop now
                </Button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default OfferPage;
