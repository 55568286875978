import { redirect } from 'react-router-dom';
import authManager from 'query/api/token/authManager';

/**
 * Prevents logged-in users from accessing logged-out routes.
 * @returns a redirection to the next route if the user is logged in, or null if logged out.
 */
const loggedOutLoader = () =>
  authManager.verify() === null
    ? redirect(
        new URLSearchParams(window.location.search).get('next') ?? '/home',
      )
    : null;

export default loggedOutLoader;
