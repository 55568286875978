import { type FC, Fragment } from 'react';
import classNames from 'classnames';
import BulletIcon from 'assets/icons/BulletIcon';
import { KardLocation, KardOffer } from 'query/kardTypes';
import { TextStyle } from 'assets/styles/styles';
import styles from './Offer.module.css';
import { formatDistance, milesAwayFrom } from '../../Locations/distance';

const offerTypeLabels = {
  INSTORE: 'In-Store',
  ONLINE: 'Online',
};

export const getOfferDetails = (
  offer: KardOffer,
  location: KardLocation | undefined,
  full: boolean,
): string[] => [
  (offerTypeLabels[offer.offerType] ?? offer.offerType) +
    (full ? ' Offer' : ''),
  ...(location !== undefined
    ? [`${formatDistance(milesAwayFrom(location.geoLocation))} mi`]
    : []),
  `${full ? 'Offer expires on' : 'Expires'} ${new Date(
    offer.expirationDate,
  ).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
  })}`,
];

const OfferDetails: FC<{
  offer: KardOffer;
  location?: KardLocation;
  full?: boolean;
}> = ({ offer, location, full = false }) => (
  <span className={classNames(TextStyle.Tiny, styles.details)}>
    {getOfferDetails(offer, location, full).map((detail, index, array) => (
      <Fragment key={detail}>
        <span>{detail}</span>
        {index < array.length - 1 && <BulletIcon />}
      </Fragment>
    ))}
  </span>
);

export default OfferDetails;
