import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import authManager from 'query/api/token/authManager';

/**
 * Prevents logged-out users from accessing logged-in routes.
 * @param param0 request from React Router.
 * @returns a redirect to the login page if the user is logged out, or null if logged in.
 */
const loggedInLoader = ({ request }: LoaderFunctionArgs) => {
  if (authManager.verify() !== null) {
    const params = new URLSearchParams();

    params.set('next', new URL(request.url).pathname);
    return redirect(`/login?${params.toString()}`);
  }

  return null;
};

export default loggedInLoader;
