import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { TextStyle } from 'assets/styles/styles';
import styles from './Input.module.css';

export type LabelableInputProps =
  | { id?: string; label?: undefined }
  | { id: string; label?: ReactNode };

type InputProps = Pick<
  JSX.IntrinsicElements['input'],
  'type' | 'name' | 'required' | 'defaultValue' | 'autoComplete'
> &
  LabelableInputProps;

const Input: FC<InputProps> = ({
  id,
  label,
  type,
  required,
  name,
  defaultValue,
  autoComplete,
}) => (
  <>
    {label && (
      <label
        className={classNames(styles.label, TextStyle.CaptionMedium)}
        htmlFor={id}
      >
        {label}
      </label>
    )}
    <div className={styles.wrapper}>
      <input
        name={name}
        id={id}
        type={type}
        required={required}
        className={classNames(styles.input, TextStyle.Caption)}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
      />
    </div>
  </>
);

export default Input;
