import { type FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { TextStyle, UtilityStyle } from 'assets/styles/styles';
import MapContainer from 'routes/sections/rewards/Locations/MapContainer';
import { locationMapQuery } from 'query/queries';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import { milesAwayFrom } from 'routes/sections/rewards/Locations/distance';
import styles from './NearbyOffers.module.css';

const NEARBY_RANGE = 200;

const NearbyOffers: FC = () => {
  const { data: locationMap } = useQuery(locationMapQuery);

  const nearbyCount = useMemo(
    () =>
      Array.from(locationMap.values()).findLastIndex(
        (location) => milesAwayFrom(location.geoLocation) <= NEARBY_RANGE,
      ) + 1,
    [locationMap],
  );

  return (
    <div className={styles.nearbyOffers}>
      <h2 className={classNames(TextStyle.P1Bold, styles.header)}>
        Nearby Offers
      </h2>
      <Link
        to="/rewards/locations/map"
        className={classNames(TextStyle.CaptionBold, styles.link)}
      >
        View map <ArrowRightIcon fill="currentcolor" />
      </Link>
      <Link
        to="/rewards/locations/map"
        className={classNames(UtilityStyle.CenterEverything, styles.map)}
      >
        <MapContainer
          locationMap={locationMap}
          defaultZoom={4}
          interactive={false}
        />
        <div className={classNames(TextStyle.VeryTiny, styles.overlay)}>
          <span>
            <b>
              {nearbyCount} offer{nearbyCount !== 1 && 's'}
            </b>{' '}
            within {NEARBY_RANGE}mi
          </span>
        </div>
      </Link>
    </div>
  );
};

export default NearbyOffers;
