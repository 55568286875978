import { FC, FormEventHandler, useState } from 'react';
import classNames from 'classnames';
import { TextStyle } from 'assets/styles/styles';
import Input from 'components/Input';
import Button from 'components/Button';
import authManager from 'query/api/token/authManager';
import styles from './Login.module.css';

const DEMO_USER_ID = 'sandbox-demoapp-new-customer';
const DEMO_USER_EMAIL = 'testuser@test-demoapp.com';

const Login: FC = () => {
  const [status, setStatus] = useState('Log in');

  const handleLogin: FormEventHandler<HTMLFormElement> = (ev) => {
    setStatus('Logging in…');

    ev.preventDefault();
    const form = ev.currentTarget;
    const formData = new FormData(form);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    authManager
      .login(email, password)
      .catch((error: Error) => setStatus(error.message));
  };

  return (
    <main className={styles.main}>
      <h1 className={classNames(TextStyle.H1Bold, styles.title)}>Login</h1>
      <form className={styles.inputs} onSubmit={handleLogin}>
        <Input
          required
          name="email"
          id="email"
          label="Email or phone number"
          defaultValue={DEMO_USER_EMAIL}
          autoComplete="username"
        />
        <Input
          required
          name="password"
          type="password"
          id="password"
          label="Password"
          defaultValue={DEMO_USER_ID}
          autoComplete="current-password"
        />
        <Button type="submit" variant="primary" className={styles.submit}>
          {status}
        </Button>
      </form>
      <div className={styles.links}>
        <p className={TextStyle.CaptionMedium}>
          Need help logging in? <u>Reset password</u>
        </p>
        <p className={TextStyle.CaptionMedium}>
          Don’t have an account? <u>Sign up</u>
        </p>
      </div>
    </main>
  );
};

export default Login;
