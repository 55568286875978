import { milesAwayFrom } from 'routes/sections/rewards/Locations/distance';
import api from './api';
import {
  KardLocation,
  KardLocationMap,
  KardMerchant,
  KardOffer,
  KardUser,
} from './kardTypes';

// TODO: these should have page/limit (through the server), maybe implement infinite scroll?

export const offersQuery = {
  queryKey: ['offers'],
  queryFn: () => api('/offers') as Promise<KardOffer[]>,
};

// TODO: limit api call to range
export const locationMapQuery = {
  queryKey: ['locations'],
  queryFn: async () =>
    new Map(
      ((await api('/locations')) as KardLocation[])
        .toSorted(
          (a, b) => milesAwayFrom(a.geoLocation) - milesAwayFrom(b.geoLocation),
        )
        .map((location) => [location._id, location]),
    ) as KardLocationMap,
  initialData: new Map() as KardLocationMap,
};

export const merchantQueryFactory = (merchantId: string) => ({
  queryKey: ['merchant', merchantId],
  queryFn: () => api(`/merchants/${merchantId}`) as Promise<KardMerchant>,
});

export const userQuery = {
  queryKey: ['user'],
  queryFn: () => api('/user') as Promise<KardUser>,
};

export const realtimeTokenQuery = {
  queryKey: ['realtime-token'],
  queryFn: () =>
    api('/realtime-token', undefined, { method: 'POST' }) as Promise<{
      subscribeKey: string;
      uuid: string;
      token: string;
    }>,
};
