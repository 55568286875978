import queryClient from 'query/queryClient';
import getNewToken from './getNewToken';
import verifyToken from './verifyToken';

export class AuthManager {
  /**
   * Whenever auth state changes (log in / log out), to prevent things from breaking
   * it's best to immediately reload the page.
   * We trust the router to realize that we no longer have access to the page we were on,
   * (login if we were logged out, or some protected route if we were logged in)
   * and forward us appropriately.
   *
   * I was initially trying to get this to work with the in-router navigation (navigate(0)),
   * but I really want this code to live outside of React land so that the route handlers can call it too.
   */
  static readonly reload = () => window.location.reload();

  static readonly localStorageTokenKey = 'token';

  private storedToken: string | null = null;

  constructor() {
    this.storedToken = localStorage.getItem(AuthManager.localStorageTokenKey);
  }

  getToken() {
    const verifyError = verifyToken(this.storedToken);

    if (verifyError === 'Token is expired') {
      this.logout();
    }

    if (verifyError !== null) {
      throw new Error(verifyError);
    }

    return (
      this.storedToken ?? localStorage.getItem(AuthManager.localStorageTokenKey)
    );
  }

  verify() {
    return verifyToken(this.storedToken);
  }

  async login(username: string, password: string) {
    this.storedToken = await getNewToken(username, password);

    localStorage.setItem(AuthManager.localStorageTokenKey, this.storedToken);
    AuthManager.reload();
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem(AuthManager.localStorageTokenKey);
    // clear query cache for security
    queryClient.clear();
    AuthManager.reload();
  }
}

export default new AuthManager();
