import { jwtDecode } from 'jwt-decode';

/**
 * Check that a JWT token is valid (format, expiration).
 * @param token the token to verify; or, the cached token if undefined.
 * @returns null if the token is valid, or an error message if it is not.
 */
export default (token: string | null) => {
  if (token === null) {
    return 'Token is null';
  }

  try {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp === undefined) {
      return 'Token missing expiration date';
    }

    if (decodedToken.exp < Date.now() / 1000) {
      return 'Token is expired';
    }

    return null;
  } catch {
    return `Token could not be decoded`;
  }
};
