import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const ArrowRightIcon: FC<SolidIconProps> = ({
  fill = 'var(--Kard-Blue, #3454E2)',
  className,
}) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="arrow-right">
      <path
        id="Vector"
        d="M14.8057 9.37843C14.8746 9.19764 14.8927 8.9987 14.8575 8.80678C14.8223 8.61486 14.7355 8.43859 14.608 8.30027L10.965 4.34372C10.8809 4.24925 10.7804 4.17389 10.6693 4.12205C10.5582 4.07021 10.4387 4.04293 10.3178 4.04179C10.1968 4.04064 10.0769 4.06567 9.96497 4.11541C9.85303 4.16514 9.75135 4.23859 9.66583 4.33146C9.58032 4.42433 9.51269 4.53477 9.46689 4.65633C9.4211 4.77789 9.39806 4.90814 9.39911 5.03948C9.40016 5.17082 9.42528 5.30061 9.47301 5.42129C9.52075 5.54197 9.59013 5.65111 9.67712 5.74236L11.7664 8.01144H3.03577C2.79422 8.01144 2.56256 8.11565 2.39176 8.30115C2.22096 8.48665 2.125 8.73824 2.125 9.00058C2.125 9.26291 2.22096 9.5145 2.39176 9.7C2.56256 9.8855 2.79422 9.98971 3.03577 9.98971H11.7664L9.67803 12.2578C9.59104 12.349 9.52166 12.4582 9.47393 12.5789C9.42619 12.6996 9.40107 12.8293 9.40002 12.9607C9.39897 13.092 9.42201 13.2223 9.46781 13.3438C9.5136 13.4654 9.58123 13.5758 9.66674 13.6687C9.75226 13.7616 9.85395 13.835 9.96588 13.8848C10.0778 13.9345 10.1977 13.9595 10.3187 13.9584C10.4396 13.9572 10.5591 13.9299 10.6702 13.8781C10.7813 13.8263 10.8818 13.7509 10.9659 13.6564L14.609 9.69989C14.6933 9.60781 14.7602 9.49857 14.8057 9.37843Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default ArrowRightIcon;
