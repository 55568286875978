import callApi from './callApi';
import authManager from './token/authManager';

/**
 * Call a protected API route.
 * @param route the route to call.
 * @param requestBody body to send as JSON.
 * @param init other options passed to a wrapped fetch call.
 * @returns a promise containing the API response.
 */
export default async (
  route: string,
  body?: object,
  { headers: givenHeaders, ...init }: RequestInit = {},
) => {
  const resBody = await callApi(`/kard${route}`, body, {
    headers: {
      Authorization: `Bearer ${authManager.getToken()}`,
      ...givenHeaders,
    },
    ...init,
  });

  return resBody;
};
