import { type FC } from 'react';
import { MerchantWithLogo } from 'routes/sections/rewards/Logo';

const MerchantBurst: FC<{ merchant: MerchantWithLogo }> = ({
  merchant: { imgUrl, name, _id: merchantId },
}) => (
  <svg
    width="164"
    height="153"
    viewBox="0 0 164 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>
      Excited abstract graphic with {name} logo and green check mark
    </title>
    <path
      d="M147.809 76.5102C147.809 102.919 124.675 125.18 93.1087 132.014C87.9822 133.121 82.774 133.805 77.5365 134.058C77.2817 134.07 77.0269 134.083 76.7703 134.092C75.4072 134.149 74.0338 134.178 72.6502 134.178C70.2444 134.178 67.8671 134.092 65.5183 133.92C64.2153 133.827 62.9232 133.708 61.642 133.564C49.1817 132.16 37.715 128.411 28.0298 122.919C26.8196 122.233 25.6379 121.52 24.4848 120.78C22.3731 119.43 20.3345 117.968 18.3774 116.401C5.44026 106.044 16.2264 83.9385 22.9688 70.0214C41.1671 32.4595 31.1417 18.8422 72.6502 18.8422C114.159 18.8422 147.809 44.6603 147.809 76.5102Z"
      fill="#F2F2F2"
    />
    <rect
      x="47.0303"
      y="14.2185"
      width="19.6193"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-46.4455 47.0303 14.2185)"
      fill="#E5E1F5"
    />
    <rect
      x="46.8062"
      y="13.9407"
      width="19.6193"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 46.8062 13.9407)"
      fill="#E5E1F5"
    />
    <rect
      x="147.809"
      y="75.1141"
      width="19.6193"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 147.809 75.1141)"
      fill="#E5E1F5"
    />
    <rect
      x="77.2637"
      y="141.18"
      width="22.3953"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 77.2637 141.18)"
      fill="#E5E1F5"
    />
    <rect
      x="38.0723"
      y="38.1619"
      width="35.8325"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 38.0723 38.1619)"
      fill="#E5E1F5"
    />
    <rect
      x="124.294"
      y="85.333"
      width="35.8325"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 124.294 85.333)"
      fill="#E5E1F5"
    />
    <rect
      x="53.749"
      y="151.399"
      width="35.8325"
      height="2.23953"
      rx="1.11977"
      transform="rotate(-44.3748 53.749 151.399)"
      fill="#E5E1F5"
    />
    <circle cx="152.288" cy="23.3213" r="4.47907" fill="#E5E1F5" />
    <circle cx="2.23953" cy="77.07" r="2.23953" fill="#E5E1F5" />
    <circle cx="25.1948" cy="138.097" r="6.15872" fill="#E5E1F5" />
    <rect
      x="40.5"
      y="37.5"
      width="83"
      height="83"
      rx="41.5"
      fill={`url(#merchant-logo-pattern-${merchantId})`}
    />
    <rect x="40.5" y="37.5" width="83" height="83" rx="41.5" stroke="#E0E1E4" />
    <rect x="114" y="24" width="24" height="24" rx="12" fill="#D1FADF" />
    <rect
      x="114"
      y="24"
      width="24"
      height="24"
      rx="12"
      stroke="#ECFDF3"
      strokeWidth="2"
    />
    <path
      d="M130 33L124.5 38.5L122 36"
      stroke="#039855"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <pattern
        id={`merchant-logo-pattern-${merchantId}`}
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref={`#merchant-logo-${merchantId}`}
          transform="scale(0.000244141)"
        />
      </pattern>
      <image
        id={`merchant-logo-${merchantId}`}
        width="4096"
        height="4096"
        xlinkHref={imgUrl}
      />
    </defs>
  </svg>
);

export default MerchantBurst;
