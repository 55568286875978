import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const NavMeIcon: FC<SolidIconProps> = ({
  fill = 'var(--300, #03276D)',
  className,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.5 12C14.9752 12 17 9.97495 17 7.5C17 5.02505 14.9752 3 12.5 3C10.0248 3 8 5.02505 8 7.5C8 9.97495 10.0248 12 12.5 12ZM12.5 14.25C9.51884 14.25 3.5 15.7688 3.5 18.75V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V18.75C21.5 15.7688 15.4812 14.25 12.5 14.25Z"
      fill={fill}
    />
  </svg>
);

export default NavMeIcon;
