import { Overlay, Map as PigeonMap, Point } from 'pigeon-maps';
import { type FC, useEffect, useState } from 'react';
import { KardLocation } from 'query/kardTypes';
import styles from './MapContainer.module.css';
import { FocusedLocationProps } from '../FocusedLocationProps';
import currentLocation from '../currentLocation';
import Pin from '../Pin';

const MapContainer: FC<
  {
    locationMap: Map<string, KardLocation>;
    interactive?: boolean;
    defaultZoom?: number;
  } & Partial<FocusedLocationProps>
> = ({
  locationMap,
  focusedLocationId,
  setFocusedLocationId,
  interactive = true,
  defaultZoom = 5,
}) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState<Point>([
    currentLocation.latitude,
    currentLocation.longitude,
  ]);

  // when a location is focused, show it on the map
  // this is necessary for when a location is focused from the carousel
  useEffect(() => {
    if (!(typeof focusedLocationId === 'string')) {
      return;
    }

    const focusedGeoLocation = locationMap.get(focusedLocationId)?.geoLocation;

    if (focusedGeoLocation === undefined) {
      return;
    }

    setCenter([focusedGeoLocation.latitude, focusedGeoLocation.longitude]);
    setZoom(defaultZoom);
  }, [defaultZoom, focusedLocationId, locationMap]);

  return (
    <PigeonMap
      zoomSnap={false}
      center={center}
      zoom={zoom}
      boxClassname={styles.mapBox}
      attribution={false}
      mouseEvents={interactive}
      touchEvents={interactive}
    >
      {Array.from(locationMap.values()).map((location) => (
        <Overlay
          key={location._id}
          anchor={[
            location.geoLocation.latitude,
            location.geoLocation.longitude,
          ]}
          style={{
            width: 49,
            height: 59,
            pointerEvents: 'none',
            zIndex: focusedLocationId === location._id ? 2 : 1,
          }}
          offset={[49 / 2, 59]}
        >
          <Pin
            location={location}
            focusedLocationId={focusedLocationId}
            setFocusedLocationId={setFocusedLocationId}
          />
        </Overlay>
      ))}
    </PigeonMap>
  );
};

export default MapContainer;
