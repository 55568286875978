import { Navigate, RouteObject } from 'react-router-dom';
import RootLayout from './RootLayout';
import SectionLayout from './sections/SectionLayout';
import LocationMap from './sections/rewards/Locations/LocationMap';
import RewardsLanding from './sections/rewards/RewardsLanding';
import RewardsLayout from './sections/rewards/RewardsLayout';
import LocationList from './sections/rewards/Locations/LocationList';
import OfferPage from './sections/rewards/OfferPage';
import RewardPage from './sections/rewards/RewardPage';
import loggedOutLoader from './logged-out/loggedOutLoader';
import Me from './sections/Me';
import loggedInLoader from './sections/loggedInLoader';
import Welcome from './logged-out/Welcome';
import Home from './sections/Home';
import Login from './logged-out/Login';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '',
        loader: loggedOutLoader,
        children: [
          { path: '', element: <Welcome /> },
          { path: '/login', element: <Login /> },
        ],
      },
      {
        path: '',
        loader: loggedInLoader,
        element: <SectionLayout />,
        children: [
          {
            path: 'home',
            element: <Home />,
          },
          {
            path: 'finances',
            element: <h1 style={{ flex: 1 }}>Finances</h1>,
          },
          {
            path: 'rewards',
            element: <RewardsLayout />,
            children: [
              {
                path: '',
                element: <RewardsLanding />,
              },
              {
                path: 'locations',
                children: [
                  {
                    path: '',
                    element: <Navigate to="map" replace />,
                  },
                  {
                    path: 'map',
                    element: <LocationMap />,
                    handle: { hideFooter: true, rewardsHeaderVariant: 'map' },
                  },
                  {
                    path: 'list',
                    element: <LocationList />,
                    handle: { rewardsHeaderVariant: 'list' },
                  },
                ],
              },
              {
                path: 'offers/:offerId',
                element: <OfferPage />,
                handle: { hideFooter: true, rewardsHeaderVariant: 'offer' },
              },
              {
                path: 'reward',
                element: <RewardPage />,
                handle: { hideFooter: true, rewardsHeaderVariant: 'minimal' },
              },
            ],
          },
          {
            path: 'me',
            element: <Me />,
          },
        ],
      },
    ],
  },
];

export default routes;
