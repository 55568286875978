import { useQuery } from '@tanstack/react-query';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { locationMapQuery } from 'query/queries';
import styles from './LocationList.module.css';
import Location from '../LocationCarousel/Location';

const LocationList: FC = () => {
  const { data: locationMap } = useQuery(locationMapQuery);
  const navigate = useNavigate();

  return (
    <div className={styles.list}>
      {Array.from(locationMap.values()).map((location) =>
        location.offers.map((offer) => (
          <Location
            onClick={() =>
              navigate(
                `/rewards/offers/${offer._id}?locationId=${location._id}`,
              )
            }
            key={`${location._id} ${offer._id}`}
            location={location}
            offer={offer}
          />
        )),
      )}
    </div>
  );
};

export default LocationList;
