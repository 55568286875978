import { type FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { locationMapQuery } from 'query/queries';
import { KardLocation } from 'query/kardTypes';
import { UtilityStyle } from 'assets/styles/styles';
import styles from './LocationMap.module.css';
import LocationCarousel from '../LocationCarousel';
import { FocusedLocationId } from '../FocusedLocationProps';
import MapContainer from '../MapContainer';

const LocationMap: FC = () => {
  const { data: locationMap } = useQuery(locationMapQuery);

  const [focusedLocationId, setFocusedLocationId] =
    useState<FocusedLocationId>(null);

  // when locations are loaded, focus the closest one
  useEffect(() => {
    setFocusedLocationId(
      (prev) =>
        prev ??
        (locationMap?.values().next().value as KardLocation | undefined)?._id ??
        null,
    );
  }, [locationMap]);

  return (
    <div className={classNames(UtilityStyle.CenterEverything, styles.mapPage)}>
      <MapContainer
        locationMap={locationMap}
        focusedLocationId={focusedLocationId}
        setFocusedLocationId={setFocusedLocationId}
      />
      <LocationCarousel
        focusedLocationId={focusedLocationId}
        setFocusedLocationId={setFocusedLocationId}
        locationMap={locationMap}
        className={styles.carousel}
      />
    </div>
  );
};

export default LocationMap;
