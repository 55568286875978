import { ComponentProps, createElement, FC, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SwipeDownIcon from 'assets/icons/SwipeDownIcon';
import { TextStyle } from 'assets/styles/styles';
import styles from './RewardsNotificationContent.module.css';
import Logo, { MerchantWithLogo } from '../Logo';

const RewardsNotificationContent: FC<{
  merchant: ComponentProps<typeof Logo>['merchant'];
  title: ReactNode;
  body?: ReactNode;
  linkProps?: ComponentProps<typeof Link>;
  className?: string;
}> = ({ merchant, title, body, linkProps, className }) =>
  createElement(
    linkProps === undefined ? Fragment : Link,
    linkProps,
    <div className={classNames(styles.notification, className)}>
      <div className={styles.details}>
        {merchant && <Logo merchant={merchant as MerchantWithLogo} size={50} />}
        <div>
          {title && <p className={TextStyle.CaptionBold}>{title}</p>}
          {body && <p className={TextStyle.Caption}>{body}</p>}
        </div>
      </div>
      <SwipeDownIcon className={styles.swipeIcon} />
    </div>,
  );

export default RewardsNotificationContent;
