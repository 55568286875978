import { type FC } from 'react';
import classNames from 'classnames';
import { KardMerchant } from 'query/kardTypes';
import QuestionMark from 'assets/images/QuestionMark.svg';
import styles from './Logo.module.css';

export interface MerchantWithLogo extends KardMerchant {
  imgUrl: string;
  name: string;
}

const Logo: FC<{
  merchant?: { imgUrl: string; name: string };
  size: number | string;
  className?: string;
}> = ({ merchant, size, className }) => (
  <img
    src={merchant?.imgUrl ?? QuestionMark}
    alt={merchant?.name ?? 'Loading\u2026'}
    width={size}
    height={size}
    className={classNames(styles.logo, className)}
  />
);

export default Logo;
