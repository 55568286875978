import { type FC } from 'react';
import classNames from 'classnames';
import { Link, useMatches, useNavigate } from 'react-router-dom';
import { TextStyle, UtilityStyle } from 'assets/styles/styles';
import SearchIcon from 'assets/icons/SearchIcon';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import ListIcon from 'assets/icons/ListIcon';
import PinIcon from 'assets/icons/PinIcon';
import styles from './RewardsHeader.module.css';

const RewardsHeader: FC = () => {
  const variant =
    (
      useMatches().find(
        (match) =>
          typeof match.handle === 'object' &&
          match.handle !== null &&
          'rewardsHeaderVariant' in match.handle &&
          typeof match.handle.rewardsHeaderVariant === 'string',
      )?.handle as { rewardsHeaderVariant: string }
    )?.rewardsHeaderVariant ?? 'landing';

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <header className={classNames(styles.header)} data-variant={variant}>
      <div
        className={classNames(
          styles.headerEarned,
          variant !== 'landing' && styles.zeroHeight,
        )}
        aria-hidden={variant !== 'landing'}
      >
        <h1 className={TextStyle.H1Bold}>Rewards</h1>
        <span className={classNames(TextStyle.CaptionBold, styles.earned)}>
          $25.78
        </span>
      </div>
      <div className={styles.searchBar}>
        <button
          className={classNames(
            styles.backButton,
            styles.iconButton,
            variant === 'landing' && styles.zeroWidth,
          )}
          aria-hidden={variant === 'landing'}
          type="button"
          aria-label="Go back to the previous page"
          onClick={goBack}
        >
          <ArrowRightIcon className={styles.backIcon} fill="currentColor" />
        </button>
        <div
          className={classNames(
            UtilityStyle.CenterEverything,
            styles.searchWrapper,
            (variant === 'offer' || variant === 'minimal') &&
              styles.zeroOpacity,
          )}
          aria-hidden={variant === 'offer' || variant === 'minimal'}
        >
          <input
            type="text"
            className={classNames(TextStyle.Caption, styles.search)}
            placeholder="Search by location or brand"
          />
          <SearchIcon className={styles.searchIcon} />
        </div>
        <Link
          replace
          to={`/rewards/locations/${variant === 'map' ? 'list' : 'map'}`}
          className={classNames(
            styles.rightButton,
            styles.iconButton,
            variant === 'landing' && styles.zeroWidth,
            (variant === 'offer' || variant === 'minimal') &&
              styles.zeroOpacity,
          )}
          aria-hidden={
            variant === 'landing' ||
            variant === 'offer' ||
            variant === 'minimal'
          }
          aria-label="Switch to list view"
        >
          {variant === 'map' ? (
            <ListIcon className={styles.iconButton} fill="currentColor" />
          ) : (
            <PinIcon className={styles.iconButton} fill="currentColor" />
          )}
        </Link>
      </div>
    </header>
  );
};

export default RewardsHeader;
