import { toast } from 'react-toastify';
import { KardReward } from 'query/kardTypes';
import { merchantQueryFactory } from 'query/queries';
import queryClient from 'query/queryClient';
import RewardsNotificationContent from '.';

export default async (reward: KardReward) => {
  const merchant = await queryClient.ensureQueryData(
    merchantQueryFactory(reward.reward.merchantId),
  );

  const pendingToastId = `toast-transaction-pending.${reward.transaction.issuerTransactionId}`;
  const pendingToastVisible = toast.isActive(pendingToastId);

  if (pendingToastVisible) {
    toast.dismiss(pendingToastId);
  }

  setTimeout(
    () => {
      toast(() => (
        <RewardsNotificationContent
          merchant={merchant}
          title="New rewards 💰"
          body={`You got cash back for spending at ${merchant.name}!`}
          linkProps={{ to: '/rewards/reward', state: { reward, merchant } }}
        />
      ));
    },
    // If we dismissed a pending toast, give it time to animate out
    pendingToastVisible ? 600 : 0,
  );
};
