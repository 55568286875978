import { type FC, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { KardLocation } from 'query/kardTypes';
import styles from './LocationCarousel.module.css';
import Location from './Location';
import { FocusedLocationProps } from '../FocusedLocationProps';

type Horse = string;
const toHorse = (location: { _id: string }, offer: { _id: string }): Horse =>
  `${location._id} ${offer._id}`;
const horseToLocationId = (horse: Horse): Horse => horse.split(' ')[0];
const horseToOfferId = (horse: Horse): Horse => horse.split(' ')[1];

const LocationCarousel: FC<
  {
    locationMap: Map<string, KardLocation>;
    className?: string;
  } & FocusedLocationProps
> = ({ locationMap, focusedLocationId, setFocusedLocationId, className }) => {
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);

  // establish a 2-way mapping between buttons and locations
  const buttonHorseMapRef = useRef<Map<HTMLButtonElement, Horse>>(new Map());
  const horseButtonMapRef = useRef<Map<Horse, HTMLButtonElement>>(new Map());

  // if the focused location changes and is not in view, scroll it into view.
  useEffect(() => {
    if (focusedLocationId !== null) {
      const focusedLocation = locationMap.get(focusedLocationId);

      if (focusedLocation === undefined) {
        return;
      }

      horseButtonMapRef.current
        .get(toHorse(focusedLocation, focusedLocation.offers[0]))
        ?.scrollIntoView({
          behavior: 'smooth',
        });
    }
  }, [focusedLocationId, locationMap]);

  // memoize ref callbacks so they don't change on every render
  const refCallbacks = useMemo(
    () =>
      Array.from(locationMap.values()).reduce((map, location) => {
        location.offers
          .map((offer) => toHorse(location, offer))
          .forEach((horse) =>
            map.set(horse, (button) => {
              if (button === null) {
                return;
              }

              buttonHorseMapRef.current.set(button, horse);
              horseButtonMapRef.current.set(horse, button);
            }),
          );
        return map;
      }, new Map<string, (button: HTMLButtonElement | null) => void>()),
    [locationMap],
  );

  // set up effects on rendered location refs
  useEffect(() => {
    const container = containerRef.current;

    if (container === null) {
      return () => {};
    }

    const cleanupFunctions: (() => void)[] = [];

    // proxy to addEventListener that will remove the listener when the component unmounts
    const listen: (
      target: EventTarget,
      ...args: Parameters<typeof EventTarget.prototype.addEventListener>
    ) => void = (target, ...args) => {
      target.addEventListener(...args);
      cleanupFunctions.push(() => target.removeEventListener(...args));
    };

    // the location in full view
    let horseInView: string | undefined;

    // note which location is currently in full view
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find((someEntry) => someEntry.isIntersecting);

        if (entry === undefined) {
          return;
        }

        horseInView = buttonHorseMapRef.current.get(
          entry.target as HTMLButtonElement,
        );
      },
      {
        root: container,
        threshold: 1,
      },
    );

    // clean up the observer when the component unmounts
    cleanupFunctions.push(() => observer.disconnect());

    // add listeners to buttons
    buttonHorseMapRef.current.forEach((horse, button) => {
      observer.observe(button);

      listen(button, 'click', () => {
        // if the location is fully in view, open it.
        if (horseInView === horse) {
          const location = locationMap.get(horseToLocationId(horse));
          const offerId = horseToOfferId(horse);

          if (location === undefined) {
            return;
          }

          navigate(`/rewards/offers/${offerId}?locationId=${location._id}`);
          return;
        }

        // otherwise, if peeking from the left/right, bring it fully into view.
        button.scrollIntoView({ behavior: 'smooth' });
      });
    });

    // after the carousel stops scrolling, focus the location in full view.
    listen(
      container,
      'scroll',
      debounce(() => {
        if (horseInView === undefined) {
          return;
        }

        setFocusedLocationId(horseToLocationId(horseInView));
      }, 200),
    );

    return () =>
      cleanupFunctions.forEach((cleanupFunction) => cleanupFunction());
  }, [locationMap, navigate, refCallbacks, setFocusedLocationId]);

  return (
    <div ref={containerRef} className={classNames(styles.carousel, className)}>
      {Array.from(locationMap.values()).map((location) =>
        location.offers.map((offer) => (
          <Location
            key={toHorse(location, offer)}
            location={location}
            offer={offer}
            ref={refCallbacks.get(toHorse(location, offer))}
          />
        )),
      )}
    </div>
  );
};

export default LocationCarousel;
