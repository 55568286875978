import { FC } from 'react';
import SolidIconProps from 'assets/icons/SolidIconProps';

const MapPinIcon: FC<
  SolidIconProps & {
    bodyClassName?: string;
    merchant: { _id: string; imgUrl?: string };
  }
> = ({
  fill = 'var(--0, white)',
  className,
  merchant: { _id: merchantId, imgUrl },
  bodyClassName,
}) => (
  <svg
    width="49"
    height="59"
    viewBox="0 0 49 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_f_180_1646)">
      <ellipse
        cx="24.2064"
        cy="51.7346"
        rx="6.37045"
        ry="2.38095"
        fill="black"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_180_1646)">
      <path
        className={bodyClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2066 4.1156C34.7615 4.1156 43.3179 13.1765 43.3179 24.3537C43.3179 32.9237 38.2876 40.2496 31.1831 43.201L25.2949 51.7239C25.1721 51.9024 25.0091 52.048 24.8197 52.1485C24.6302 52.2491 24.4199 52.3015 24.2064 52.3015C23.9929 52.3015 23.7825 52.2491 23.593 52.1485C23.4036 52.048 23.2406 51.9024 23.1178 51.7239L17.2294 43.2007C10.1252 40.2492 5.09521 32.9235 5.09521 24.3537C5.09521 13.1765 13.6517 4.1156 24.2066 4.1156Z"
        fill={fill}
      />
    </g>
    <path
      d="M40.3202 24.3537C40.3202 14.9297 33.106 7.29016 24.2068 7.29016C15.3075 7.29016 8.09326 14.9297 8.09326 24.3537C8.09326 33.7776 15.3075 41.4171 24.2068 41.4171C33.106 41.4171 40.3202 33.7776 40.3202 24.3537Z"
      fill={`url(#merchant-pin-pattern-${merchantId})`}
    />
    <defs>
      <filter
        id="filter0_f_180_1646"
        x="15.8359"
        y="47.3536"
        width="16.7407"
        height="8.76196"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_180_1646"
        />
      </filter>
      <filter
        id="filter1_d_180_1646"
        x="0.0952148"
        y="0.115601"
        width="48.2227"
        height="58.1859"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_180_1646"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_180_1646"
          result="shape"
        />
      </filter>
      <pattern
        id={`merchant-pin-pattern-${merchantId}`}
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref={`#merchant-pin-icon-${merchantId}`}
          transform="matrix(0.00529478 0 0 0.005 -0.0294785 0)"
        />
      </pattern>
      {imgUrl && (
        <image
          id={`merchant-pin-icon-${merchantId}`}
          width="200"
          height="200"
          xlinkHref={imgUrl}
        />
      )}
    </defs>
  </svg>
);

export default MapPinIcon;
