import { type FC } from 'react';
import SolidIconProps from './SolidIconProps';

const SwipeDownIcon: FC<SolidIconProps> = ({
  className,
  fill = 'var(--200, #E0E1E4)',
}) => (
  <svg
    width="26"
    height="4"
    viewBox="0 0 26 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="26" height="4" rx="2" fill={fill} />
  </svg>
);

export default SwipeDownIcon;
