import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const BulletIcon: FC<SolidIconProps> = ({
  fill = 'var(--500, #8D8D94)',
  className,
}) => (
  <svg
    width="3"
    height="3"
    viewBox="0 0 3 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.5" cy="1.5" r="1.5" fill={fill} className={className} />
  </svg>
);

export default BulletIcon;
