import { type FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import SolidIconProps from 'assets/icons/SolidIconProps';
import { TextStyle } from 'assets/styles/styles';
import styles from './NavTile.module.css';

const NavTile: FC<{
  name: string;
  icon: FC<SolidIconProps>;
  route: string;
}> = ({ name, icon: Icon, route }) => {
  const active = useMatch(`${route}/*`) !== null;

  return (
    <Link
      to={route}
      className={classNames({ [styles.tile]: true, [styles.active]: active })}
      aria-current={active ? 'page' : undefined}
    >
      <Icon
        fill={active ? 'var(--Deep-Blue' : 'var(--300)'}
        className={styles.icon}
      />
      <span className={TextStyle.TinyMedium}>{name}</span>
    </Link>
  );
};

export default NavTile;
