import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const ListIcon: FC<SolidIconProps> = ({
  fill = 'var(--0, white)',
  className,
}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.5 5.5C2.94772 5.5 2.5 5.94772 2.5 6.5C2.5 7.05228 2.94772 7.5 3.5 7.5H3.51C4.06228 7.5 4.51 7.05228 4.51 6.5C4.51 5.94772 4.06228 5.5 3.51 5.5H3.5Z"
      fill={fill}
    />
    <path
      d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5H21.5C22.0523 7.5 22.5 7.05228 22.5 6.5C22.5 5.94772 22.0523 5.5 21.5 5.5H8.5Z"
      fill={fill}
    />
    <path
      d="M8.5 11.5C7.94772 11.5 7.5 11.9477 7.5 12.5C7.5 13.0523 7.94772 13.5 8.5 13.5H21.5C22.0523 13.5 22.5 13.0523 22.5 12.5C22.5 11.9477 22.0523 11.5 21.5 11.5H8.5Z"
      fill={fill}
    />
    <path
      d="M7.5 18.5C7.5 17.9477 7.94772 17.5 8.5 17.5H21.5C22.0523 17.5 22.5 17.9477 22.5 18.5C22.5 19.0523 22.0523 19.5 21.5 19.5H8.5C7.94772 19.5 7.5 19.0523 7.5 18.5Z"
      fill={fill}
    />
    <path
      d="M2.5 12.5C2.5 11.9477 2.94772 11.5 3.5 11.5H3.51C4.06228 11.5 4.51 11.9477 4.51 12.5C4.51 13.0523 4.06228 13.5 3.51 13.5H3.5C2.94772 13.5 2.5 13.0523 2.5 12.5Z"
      fill={fill}
    />
    <path
      d="M3.5 17.5C2.94772 17.5 2.5 17.9477 2.5 18.5C2.5 19.0523 2.94772 19.5 3.5 19.5H3.51C4.06228 19.5 4.51 19.0523 4.51 18.5C4.51 17.9477 4.06228 17.5 3.51 17.5H3.5Z"
      fill={fill}
    />
  </svg>
);

export default ListIcon;
