import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { KardOffer } from 'query/kardTypes';
import { TextStyle } from 'assets/styles/styles';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';
import styles from './Offer.module.css';
import OfferDetails from './OfferDetails';
import Logo from '../../Logo';

const Offer = forwardRef<
  HTMLButtonElement,
  {
    offer: KardOffer;
  }
>(({ offer }, ref) => {
  const navigate = useNavigate();
  const openOffer = () => navigate(`/rewards/offers/${offer._id}`);

  return (
    <button
      className={styles.offer}
      ref={ref}
      type="button"
      onClick={openOffer}
    >
      <div className={styles.logoStoreDetails}>
        <Logo size={46} className={styles.logo} merchant={offer.merchant} />
        <div className={styles.storeDetails}>
          <span className={TextStyle.CaptionMedium}>{offer.name}</span>
          {/* TODO: instore distance */}
          <OfferDetails offer={offer} />
        </div>
      </div>
      <div className={styles.cashbackArrow}>
        <span className={styles.cashback}>
          <span className={TextStyle.CaptionBold}>
            {offer.totalCommission}%
          </span>
          <span className={TextStyle.TinyBold}>Cash Back</span>
        </span>
        <ChevronRightIcon />
      </div>
    </button>
  );
});

export default Offer;
