import { FC } from 'react';
import SolidIconProps from './SolidIconProps';

const NavMeIcon: FC<SolidIconProps> = ({
  fill = 'var(--300, #03276D)',
  className,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000"
    className={className}
  >
    <path
      d="M8.75 4.5C8.75 3.67157 9.42157 3 10.25 3C11.0784 3 11.75 3.67157 11.75 4.5V19.5C11.75 20.3284 11.0784 21 10.25 21C9.42157 21 8.75 20.3284 8.75 19.5V4.5Z"
      fill={fill}
    />
    <path
      d="M4.25 17.25C4.25 16.4216 4.92157 15.75 5.75 15.75C6.57843 15.75 7.25 16.4216 7.25 17.25V19.5C7.25 20.3284 6.57843 21 5.75 21C4.92157 21 4.25 20.3284 4.25 19.5V17.25Z"
      fill={fill}
    />
    <path
      d="M13.25 14.25C13.25 13.4216 13.9216 12.75 14.75 12.75C15.5784 12.75 16.25 13.4216 16.25 14.25V19.5C16.25 20.3284 15.5784 21 14.75 21C13.9216 21 13.25 20.3284 13.25 19.5V14.25Z"
      fill={fill}
    />
    <path
      d="M17.75 9.75C17.75 8.92157 18.4216 8.25 19.25 8.25C20.0784 8.25 20.75 8.92157 20.75 9.75V19.5C20.75 20.3284 20.0784 21 19.25 21C18.4216 21 17.75 20.3284 17.75 19.5V9.75Z"
      fill={fill}
    />
  </svg>
);

export default NavMeIcon;
