import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { userQuery } from 'query/queries';
import Button from 'components/Button';
import { TextStyle } from 'assets/styles/styles';
import authManager from 'query/api/token/authManager';
import styles from './Me.module.css';

const Me: FC = () => {
  const { data: user } = useQuery(userQuery);

  return (
    <main className={styles.main}>
      <h1 className={TextStyle.H1Bold}>
        {user?.firstName !== undefined && user.lastName !== undefined
          ? `${user?.firstName} ${user?.lastName}`
          : 'Profile'}
      </h1>
      <Button className={styles.button} onClick={() => authManager.logout()}>
        Log out
      </Button>
    </main>
  );
};

export default Me;
