import styles from './styles.module.css';

export const TextStyle = {
  H1Bold: styles['h1-bold'],
  H1: styles.h1,
  P1Bold: styles['p1-bold'],
  P1Medium: styles['p1-medium'],
  P1: styles.p1,
  CaptionBold: styles['caption-bold'],
  CaptionMedium: styles['caption-medium'],
  Caption: styles.caption,
  TinyBold: styles['tiny-bold'],
  TinyMedium: styles['tiny-medium'],
  Tiny: styles.tiny,
  VeryTiny: styles['very-tiny'],
  Shadowed: styles.shadowed,
};

export const EffectStyle = {
  Shadowed: styles.shadowed,
};

export const UtilityStyle = {
  CenterEverything: styles['center-everything'],
};
