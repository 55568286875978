import verifyToken from './verifyToken';
import callApi from '../callApi';

/**
 * Fetch a new token.
 * @param username
 * @param password
 * @returns a promise containing the new token.
 */
export default async (username: string, password: string) => {
  const resBody = await callApi(
    '/auth/token',
    { username, password },
    { method: 'POST' },
  );

  if (!('token' in resBody) || typeof resBody.token !== 'string') {
    throw new Error('Server did not return a token');
  }

  const verifyErr = verifyToken(resBody.token);

  if (verifyErr !== null) {
    throw new Error(verifyErr);
  }

  return resBody.token;
};
