import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'query/queryClient';
import AppNotification from './AppNotification';

const AppLayout: FC = () => (
  <QueryClientProvider client={queryClient}>
    <AppNotification />
    <Outlet />
  </QueryClientProvider>
);

export default AppLayout;
