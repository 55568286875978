import { FC } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { NullablePubnubProvider } from 'query/PubnubContext';
import SectionFooter from './SectionFooter';
import styles from './SectionLayout.module.css';

const SectionLayout: FC = () => {
  const hideFooter = useMatches().some(
    (match) =>
      typeof match.handle === 'object' &&
      match.handle !== null &&
      'hideFooter' in match.handle &&
      match.handle?.hideFooter === true,
  );

  return (
    <NullablePubnubProvider>
      <div className={styles.app}>
        <Outlet />
        <SectionFooter hidden={hideFooter} />
      </div>
    </NullablePubnubProvider>
  );
};

export default SectionLayout;
