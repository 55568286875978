import { KardIncomingTransaction, KardOffer, KardUser } from 'query/kardTypes';

export const amount = ({
  minTransactionAmount,
  maxTransactionAmount,
}: {
  minTransactionAmount?: number;
  maxTransactionAmount?: number;
}) => {
  const min = minTransactionAmount ?? 0;
  const max = maxTransactionAmount ?? min + 50e2;

  return Math.floor(Math.random() * (max - min)) + min;
};

export default (
  user: KardUser,
  offer: KardOffer,
): Partial<KardIncomingTransaction> => ({
  transactionId: `demo-transaction-${Math.floor(Math.random() * 1e6)
    .toString(36)
    .padStart(4, '0')}`,
  referringPartnerUserId: user.referringPartnerUserId,
  status: 'APPROVED',
  amount: amount(offer),
  currency: 'USD',
  description: offer.merchant.name.toUpperCase().replace(/[^\w\s]/, ''),
  merchantName: offer.merchant.name,
  authorizationDate: new Date().toISOString(),
  cardLastFour: user.cards?.[0].last4,
});
