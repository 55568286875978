import { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { TextStyle } from 'assets/styles/styles';
import styles from './Welcome.module.css';

const Welcome: FC = () => (
  <main className={styles.main}>
    <Button variant="secondary" className={styles.logo}>
      Logo
    </Button>
    <div className={styles.buttons}>
      <Link to="/login" className={styles.loginLink}>
        <Button variant="secondary">Login</Button>
      </Link>
      <p className={TextStyle.CaptionMedium}>
        Don’t have an account? <u>Sign up</u>
      </p>
    </div>
  </main>
);

export default Welcome;
