import { FC } from 'react';
import { KardOffer } from 'query/kardTypes';
import Offer from './Offer';
import styles from './OffersList.module.css';

const OffersList: FC<{ offers: KardOffer[] }> = ({ offers }) => (
  <div className={styles.offersList}>
    {offers.map((offer) => (
      <Offer key={offer._id} offer={offer} />
    ))}
  </div>
);

export default OffersList;
