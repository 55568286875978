import { type FC } from 'react';
import SolidIconProps from './SolidIconProps';

const SearchIcon: FC<SolidIconProps> = ({
  fill = 'var(--500, "#8D8D94")',
  className,
}) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 6.91667C2.5 4.75355 4.25355 3 6.41667 3C8.57978 3 10.3333 4.75355 10.3333 6.91667C10.3333 7.96762 9.9194 8.92189 9.24568 9.62535C9.2237 9.64288 9.20247 9.66181 9.18211 9.68217C9.16176 9.70252 9.14283 9.72374 9.12531 9.74573C8.42185 10.4194 7.4676 10.8333 6.41667 10.8333C4.25355 10.8333 2.5 9.07978 2.5 6.91667ZM9.67979 11.2405C8.77226 11.9265 7.64197 12.3333 6.41667 12.3333C3.42512 12.3333 1 9.90821 1 6.91667C1 3.92512 3.42512 1.5 6.41667 1.5C9.40821 1.5 11.8333 3.92512 11.8333 6.91667C11.8333 8.142 11.4265 9.27231 10.7405 10.1799L12.7803 12.2197C13.0732 12.5126 13.0732 12.9874 12.7803 13.2803C12.4874 13.5732 12.0125 13.5732 11.7196 13.2803L9.67979 11.2405Z"
      fill={fill}
    />
  </svg>
);

export default SearchIcon;
