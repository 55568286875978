import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import RewardsHeader from './RewardsHeader';

const RewardsLayout: FC = () => (
  <>
    <RewardsHeader />
    <main>
      <Outlet />
    </main>
  </>
);

export default RewardsLayout;
