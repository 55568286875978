import { type FC } from 'react';
import NearbyOffers from './NearbyOffers';
import AvailableOffers from './AvailableOffers';
import styles from './RewardsLanding.module.css';

const RewardsLanding: FC = () => (
  <div className={styles.landing}>
    <NearbyOffers />
    <AvailableOffers />
  </div>
);

export default RewardsLanding;
