import { toast } from 'react-toastify';
import Pubnub from 'pubnub';
import { postTransaction } from 'query/mutations';
import { KardOffer, KardUser } from 'query/kardTypes';
// import Checkmark from 'assets/images/Checkmark.svg';
import createTransaction from './createTransaction';
import styles from '../RewardsNotificationContent/RewardsNotificationContent.module.css';
import RewardsNotificationContent from '../RewardsNotificationContent';

export default (pubNub: Pubnub, user: KardUser, offer: KardOffer) => {
  const transaction = createTransaction(user, offer);

  // show debug notification
  const toastId = toast(
    () => (
      <RewardsNotificationContent
        className={styles.debug}
        merchant={offer.merchant}
        title={<>Simulating a purchase</>}
        body={<>This should only take a moment&hellip;</>}
      />
    ),
    { toastId: `toast-transaction-pending.${transaction.transactionId}` },
  );

  // show the loading state for a minimum of 3 seconds
  Promise.all([
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
    postTransaction(transaction),
  ])
    // .then(() =>
    //   toast.update(toastId, {
    //     render: () => (
    //       <RewardsNotificationContent
    //         className={styles.debug}
    //         merchant={{
    //           imgUrl: Checkmark,
    //           name: 'Green check mark indicating success',
    //         }}
    //         title="Transaction complete"
    //         body="Your reward should appear shortly"
    //       />
    //     ),
    //   }),
    // )
    .catch((error: Error) =>
      toast.update(toastId, {
        render: () => (
          <RewardsNotificationContent
            className={styles.debug}
            merchant={offer.merchant}
            title="Simulating purchase failed"
            body={error.message}
          />
        ),
      }),
    );
  // .finally(() =>
  //   setTimeout(() => {
  //     toast.dismiss(toastId);
  //   }, 5000),
  // );
};
